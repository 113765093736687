<template>
  <div>
    <MyHeader />
    <main id="content">
      <div class="container">
        <br class="mb-5">
        <div class="row align-items-center justify-content-center">
          <div class="col-12">
            <h5>TERMINOS Y CONDICIONES GENERALES DE USO DE LA PLATAFORMA <a href="/">WWW.HOMEBELIKE.COM</a> Y SUS SERVICIOS.</h5>
            <br>
            <p><b>1. DEFINICIONES:</b></p>

            <p>
              1.1 USUARIO: Es la persona física o jurídica que utiliza la plataforma con el propósito de publicar su vivienda para alquiler de corta estadía o vacacional (más delante de forma especifica EL ANFITRIÓN), o la persona que ingresa para identificar y ubicar una vivienda para alquiler de corta estadía o vacacional (más delante de forma especifica EL INQUILINO). Es usuario tanto EL ANFITRIÓN como EL HUESPED, así como todo el que accede a la página web WWW.HOMEBELIKE.COM independientemente de que haya abierto una cuenta o no, y por tanto, el uso, lectura o exploración del contenido de la página hecha por esta, ser hará en estricto apego a éstos términos y condiciones
            </p>

            <p>1.2 PLATAFORMA: Es el sitio web www.homebelike.com que pone a disposición del usuario, la posibilidad de identificar una vivienda de alquiler de corta estadía, de modo que pueda o no mostrar interés en rentarla. La referencia a la plataforma incluye la sociedad comercial en virtud de la cual opera, en lo adelante referida como HBL.</p>

            <p>1.3 BUENA FE: Es la disposición general de las personas de que sus actuaciones se hacen con un fin legítimo, sin la más mínima intención de causar daño o perjuicio alguno; ni sacar ventajas desproporcionadas o ilegítimas.</p>

            <p>1.4 HBL INTERNATIONAL DESTINATIONS ®  Es una sociedad comercial constituida conforme a las leyes de LUXEMBURGO, que es la proveedora de los servicios de la plataforma www.homebelike.com.</p>

            <p>1.5 ESPECIFICIDAD: Queda expresamente entendido que HBL no se compromete más allá que las obligaciones contenidas en este acuerdo y el alcance de sus servicios son hechos en el contexto preciso de estos términos, sin que medien otras obligaciones que no hayan sido previamente convenidas.</p>

            <p>2. ACEPTACIÓN DE ESTOS TÉRMINOS POR EL USO DE LA PLATAFORMA O EL PORTAL WEB.</p>

            <p>2.1 El usuario reconoce la aceptación de éstos términos haciendo click al final del documento en la sección de “aceptación” que ha leído íntegramente este documento y lo ha comprendido y entendido, así como aceptado las reglas y deberes indicadas HBL.</p>

            <p>2.2 El ingreso a la página o la creación de la cuenta del usuario en www.homebelike.com es la prueba fehaciente de que el usuario ha entendido y aceptado todos los términos y condiciones establecidos aquí; además comprende que la plataforma no tiene ninguna obligación o deber más allá del indicado en estos términos y por lo tanto, ninguna imputación del usuario como deber atribuible a la plataforma es válida o tendrá efecto, salvo que se demuestre judicialmente, que ésta, a través de los órganos de dirección y control de la sociedad HBL, los ha aceptado de manera previa, por escrito y sin reservas.</p>

            <p>2.3. Modificaciones a los términos. HBL se reserva el derecho a modificar los presentes Términos en cualquier momento con arreglo a esta disposición. Con relación a lo anterior, HBL en la búsqueda del mejor servicio al cliente se compromete a realizar las siguientes tareas:</p>

            <p>2.3.1 Actualizar las publicaciones en la plataforma digital con las modificaciones realizadas a los presentes Términos.</p>

            <p>2.3.2 Avisar - notificar a los usuarios suscritos en la plataforma dichas modificaciones mediante correo electrónico, como mínimo, veinte (20) días antes de que estas entren en vigencia.  5 DIAS</p>

            <p>2.3.3 Informar del derecho a rescindir o terminar el Contrato en el contenido del correo electrónico de notificación. En el caso e desacuerdo con los Términos modificados el cliente podrá rescindir el presente Contrato de manera inmediata y explícita. SIEMPRE CUMPLIENDO CON LOS TERMINOS DE CANCELACION DE ESTE CONTRATO.</p>

            <p>2.3.4 Renovar de manera automática el acceso o utilización de la plataforma de HBL  en caso de que el usuario no exprese de forma explícita dar por terminado su contrato antes de la fecha en que entren en vigor los términos modificados, lo cual se entenderá como la aceptación de los términos actualizados.</p>

            <p>3. DECLARACIÓN DE AUTORIDAD Y LIBRE CONSENTIMIENTO.</p>

            <p>3.1 El usuario reconoce que es mayor de edad conforme a la legislación de su país de origen y y que asimismo, es mayor que 21 años.</p>

            <p>3.2 Reconoce que está en pleno uso de sus facultades mentales y que no ha sido coaccionado de ninguna manera para acceder y aceptar las condiciones y términos requeridos para ingresar a la plataforma.</p>

            <p>3.3 Si el usuario es una persona moral, entonces quien solicita los servicios reconoce y declara que tiene la capacidad legal para representar a dicha compañía y está habilitado por los órganos de gestión que tienen el poder de dirección y administración empresarial.</p>

            <p>3.4 En caso de que el consentimiento no tenga validez por violentar lo aquí contenido o las normas que lo regulan, el usuario reconoce que su acto es nulo y por tanto, no genera efecto de ninguna naturaleza frente a la plataforma o a la sociedad HBL; por tanto se considera un hecho del cual solo el suscribiente es responsable y se declara único responsable de lo que se derive de su actuación indebida y comportamiento negligente.</p>

            <p>3.5 Los usuarios que sean LOS ANFITRIONES reconocen y declaran formalmente que son titulares de los derechos de propiedad sobre los inmuebles que ofertan o publican y que tienen autoridad legal para administrarlos, cederlos en alquiler y realizar actos jurídicos que engloben y abarquen la naturaleza que los referidos en estos términos y en los servicios ofertados por HBL. En caso de no ser propietarios, reconocen tener la capacidad legal según poderes válidos, vinculantes y vigentes, para ceder en alquiler la propiedad, así como ejercer con esta actos de administración y conservación. En caso de cesación de sus mandatos, se obligan de inmediato a notificarlo a HBL. Se considera que la no notificación de la revocación de los poderes, genera para los usuarios en general y para HBL una apariencia legítima de que el mandato está vigente, hasta tanto sea formalmente notificado. Que pasa con brokers? Uso de otro contrato? . Reservas futuras. Poder para mostartlos?. Este articulo no se debe de poner en el otro contrato?</p>

            <p>4. CREACIÓN DE LA CUENTA.</p>

            <p>4.1 Cada usuario (persona o empresa) deberá registrar una cuenta ("Cuenta de HBL") para acceder a la plataforma, no podrá registrar más de una, ni cederla, ni transferirlo, el cual llenará con su información básica que se detalla en el formulario denominado FORMULARIO DE CONTENIDO, disponible en la sección “sign in” o “regístrate”. Llamarlo de Afiliacion.</p>

            <p>4.2 El perfil al ser aprobado por el sistema, le permitirá publicar o reservar un anuncio de vivienda o inmueble de alquiler de corta estadía, así como otros anuncios que se vinculen a los servicios de alquiler de viviendas, que se indican más adelante</p>

            <p>4.3 Las sociedades o personas jurídicas también podrán registrar un perfil donde deberá declarar y garantizar que cuenta con la potestad para vincular legalmente a dicha entidad y otorgarnos todos los permisos y licencias requeridos en los presentes términos.</p>

            <p>4.4 Alternativamente el usuario podrá crear su cuenta a través de su cuenta de la red social “Facebook”. A su vez, tendrá la opción de desactivar el vínculo entre su perfil de HBL  y la cuenta de “Facebook” en cualquier momento.</p>

            <p>4.5 CUENTA DEL ANFITRIÓN. EL ANFITRIÓN creará una cuenta personal mediante el ingreso de su correo electrónico, su nombre clave o apodo y el código de acceso de seguridad que será elegido por éste; indicará sus datos personales, como nombre según figura en su pasaporte o documento de identidad nacional, dirección, teléfono, celular, datos de la vivienda, lugar donde está ubicada, descripción, fotos y todos los datos requeridos como “campos obligatorios” en el FORMULARIO DE VINCULACÓN DE VIVIENDA disponible en este sitio web.</p>

            <p>4.6 CUENTA DEL INQUILINO. EL INQUILINO creará una cuenta personal mediante el ingreso de su correo electrónico, su nombre clave o apodo y el código de acceso de seguridad que será elegido por éste; indicará sus datos personales, como nombre según figura en su pasaporte o documento de identidad nacional, dirección, teléfono, celular. La plataforma pondrá a su disposición una sección para subir su documento de identidad y hará una marca específica para que LOS ANFITRIONES sepan qué usuarios han confirmado su identidad a través de un documento válido. Puedo o no hacerlo.</p>

            <p>4.7 Si el usuario tiene distintas direcciones de correo electrónico, deberá usar una sola para la plataforma e indicar en esta, sus cuentas de correo alternativas para que no replique una cuenta usando un correo distinto.</p>

            <p>4.8 El usuario será responsable por todas las operaciones efectuadas en su cuenta; ya que solo éste tiene acceso a la misma con su usuario y código de acceso. El usuario tiene derecho a notificar a la plataforma cualquier acceso no autorizado a su cuenta, así como al cambio de clave o recuperación de clave cuando lo entienda necesario, usando las instrucciones que se indicarán ante esa eventualidad.</p>

            <p>4.9 HBL no es responsable por la idoneidad de la información provista ni de la veracidad de los datos publicados sobre las viviendas o los anfitriones e inquilinos.</p>

            <p>4.10 Los usuarios aceptan que existe siempre el riesgo en internet de personas que pretendan o intenten usar la plataforma sin información precisa, así como la creación de cuentas irreales. Para todos esos casos, HBL hará sus mejores esfuerzos según la disponibilidad de tecnología disponible (bots, inteligencia virtual, entre otros) para evitar cuentas ficticias o eliminar las creadas. Igualmente, la plataforma pone a disposición de los usuarios una sección de DENUNCIAS, para reportar cualquier tipo de actividad anormal. De igual forma la plataforma pone a disposición de los usuarios las evaluaciones bien sea del ANFITRIÓN o del INQUILINO, que podrá servir de referencia para otros usuarios, sin que necesariamente HBL certifique o respalde dichas referencias. Puede poner a disposición.</p>

            <p>4.11 HBL podrá tener ANFITRIONES e INQUILINOS confirmados, que se tratarán de aquellos que se han vinculado a la plataforma de manera presencial, es decir, físicamente o que han pasado una serie de pruebas y confirmaciones aceptables por HBL para confirmar su identidad. Esos ANFITRIONES o INQUILINOS tendrán un sello de reconocimiento de HBL, sin embargo, dicho sello no será garantía de su comportamiento, del cumplimiento de sus obligaciones frente a los inquilinos o de su idoneidad como anfitriones.</p>

            <p>4.12 El usuario deberá notificar a HBL si la información contenida en su perfil ha sido robada, extraviada, objeto de apropiación indebida o se ven amenazadas de algún modo, o de algún caso de un uso no autorizado o sospecha del mismo. En los casos donde el usuario no reporte las situaciones antes mencionadas o realice actividades ilícitas con su perfil es completamente responsabilidad de la persona que se registró en HBL (y no de esta sociedad), y éste podrá tomar acciones legales frente a ese usuario.</p>

            <p>4.13 Los usuarios son los únicos responsables de la información que indican en el registro de sus cuentas. Aceptan que la plataforma entregue información sobre IP o cualquier trazo de seguridad online a cualquier autoridad competente, para detectar uso indebido de ésta. Que pasa si nos hacen robo de identidad a nosotros?</p>

            <p>4.14 La plataforma tiene el derecho de exigir de algunos usuarios, según corresponda o según sea necesario, de información adicional para comprobar su identidad o para confirmar los datos asentados. En caso de que un usuario no colabore con el requerimiento de información adicional o comprobación de datos, la cuenta será suspendida o cancelada, según sea pertinente.</p>

            <p>4.15 En el caso de que HBL  detecte o sea reportado contenido publicado, difundido, enviado o transmitido fraudulento, falso, engañoso (directamente o por omisión o no actualización de la información) o falaz; (ii) obsceno, pornográfico, vulgar u ofensivo, difamatorio, injurioso; ó (iii) promueva la discriminación, la intolerancia, el racismo, el odio, el acoso o el daño contra cualquier persona o grupo; (iv) sea violento o amenazador o fomente la violencia o acciones que sean amenazantes sobre cualquier otra persona o animal; (v) promueva actividades o sustancias ilícitas o dañinas; o (vi) incumpla con los presentes términos y condiciones de HBL   o cualquier otra política de HBL ; sin aviso previo, se retirará o desactivará el acceso al perfil y contenido del usuario. Si algún usuario determina que algún contenido en la Plataforma de HBL  infringe los derechos de autor que posee, podrá notificarlo mediante correo electrónico en la sección de quejas o comentarios de la página web. Sin derecho a reclamo sea error, verdadero o no</p>

            <p>5. OBLIGACIÓN DE INTEGRIDAD DE LA INFORMACIÓN. USO DE LA INFORMACIÓN.</p>

            <p>5.1 El usuario es el único responsable de asegurar y dar fe de que el contenido de su información no viola la ley, las leyes o reglamentos, así como derechos de terceros. HBL aunque no es responsable ni tiene deber de auditoría de la información, eliminará cualquier información o publicación que sea contraria a la ley, los usos y las buenas costumbres, según su criterio y a discreción propia.</p>

            <p>5.2 Los usuarios garantizan que no usarán su cuenta para vulnerar, afectar o violar derechos de autor, propiedad industrial. Usarán su cuenta de buena fe y con apego a las normas comerciales o del consumidor aplicables, así como con el cuidado y deber de una persona razonable y de una persona prudente. El usuario descarga y consecuentemente declara a HBL libre e indemne de obligaciones, responsabilidades y reclamaciones que surjan del mal uso o manejo de su cuenta, o de la violación de estos términos o condiciones o de negligencia del usuario, sea por acción o por omisión.</p>

            <p>5.3 HBL utilizará canales de distribución en internet para difundir el contenido de las ofertas de las viviendas o inmuebles de alquiler colocados en www.homebelike.com de modo que las búsquedas de potenciales inquilinos tenga un mejor y mayor alcance, esto incluye aplicaciones de búsquedas, redes sociales, correos publicitarios, aplicaciones de teléfonos móviles, entre otros. En ese caso, esas publicaciones seguirán reguladas por estos términos y condiciones. Otros vacation rentals????</p>

            <p>5.4 El usuario declara y garantiza a HBL que la información que suministra tanto de su cuenta como de sus anuncios es real y precisa.</p>
            <p>5.5 EL ANFITRIÓN otorga y concede a favor de HBL, una licencia irrevocable, perpetua, mundial y libre de regalías, de modo que está perfectamente habilitada en derecho y equidad para revelar al público en general, directamente o usando servicios de divulgación de información, toda la información que ha sido suministrada por EL ANFITRIÓN para el alquiler de las viviendas.</p>
            <p>5.6 De igual forma, EL INQUILINO acepta que HBL se base de información básica (sin revelar la identidad a terceros) para personalizarle ofertas publicitarias y otras opciones disponibles de alquileres de viviendas o inmuebles.</p>
            <p>5.7 El usuario declara y garantiza del modo más absoluto posible, que es titular por derecho, licencia o uso permitido, de todos los derechos de autor o de propiedad industrial relacionados con la información subida a la plataforma.</p>
            <p>5.8 LOS ANFITRIONES garantizan a HBL y a los demás usuarios, conocidos o no, así como al público en general, que tienen el derecho de ofrecer y publicar las ofertas contenidas en sus cuentas, al tenor del alcance expresado en las mismas y sin reservas de ningún tipo, salvo que se indiquen expresamente en la plataforma, de manera clara y visible para todos los usuarios.</p>
            <p>5.9 Ninguna información provista por los usuarios se hará para perjudicar cualquier derecho de otro usuario, de la plataforma y de terceros. Los usuarios tienen un deber general de buena fe, de usar el servicio del modo más prístino posible, cuidando de la información como un buen padre de familia o persona razonable y precavida.</p>
            <p>5.10 Cualquier tercero que se sienta afectado tendrá el derecho de pedir a la plataforma la remoción o limitación de cualquier publicación que vulnere o atente contra sus derechos y le otorgará a la plataforma un plazo razonable para tomar cualquier medida que corresponda en procura de sus derechos.</p>

            <p>6. NATURALEZA DEL SERVICIO:</p>

            <p>6.1 HBL y su plataforma es una simple facilidad al público para intercambio de información sobre ofertas y disponibilidad para alquiler, de precios de viviendas o inmuebles e alquiler de corta estadía.</p>

            <p>6.2 HBL podrá igualmente permitir publicar ofertas relacionadas de terceros como excursiones, actividades de diversión o entretenimiento, disposición de trabajo por personal de apoyo contratado para servicios específicos, alquileres de vehículos, de equipos, entre otros. Donde no es responsable. Como especificar que?</p>

            <p>6.3 HBL no es el titular ni detentador de lo ofrecido en la plataforma; tampoco interviene en forma alguna en las eventuales operaciones que se realicen entre usuarios. No participa ni responde por la existencia, calidad, cantidad, estado, integridad o legitimidad de los bienes o servicios ofrecidos, o adquiridos por los usuarios.</p>

            <p>6.4 En tal virtud, HBL no es ni representante ni agente, ni mandatario, ni relacionado jurídicamente con las actividades de los usuarios. El rol de HBL es la administración de la plataforma de la que se sirven los usuarios para intercambio de los servicios publicados por éstos y subidos al portal web, de modo que reserven y alquilen las propiedades disponibles y algunos servicios relacionados con éstas, de estar ofertados en la página por sus anfitriones. Quitar</p>

            <p>6.5 En ese tenor, HBL no será responsable por el efectivo cumplimiento de las obligaciones asumidas por los usuarios. Las relaciones entre los usuarios solo le atañen a éstos y solo entre éstos se pueden formular reclamaciones. La plataforma quedará libre e indemne por esas eventualidades, salvo los casos donde esta genere su propia falta, que deberá quedar establecida judicialmente.</p>

            <p>6.6 La plataforma no asume ninguna responsabilidad por el uso de ésta y se libera de toda obligación o responsabilidad que pueda resultar por errores u omisiones por el contenido o de la información suministrada por los usuarios, incluyendo pero no limitada a, imprecisiones técnicas y errores de fechas, datos de pago, de identificación, referencias a otras páginas o correos, teléfonos, direcciones.</p>

            <p>6.7 HBL no será responsable de mal funcionamiento de hiperenlaces; la falta de disponibilidad de acceso a la plataforma; a cualquier porción de la misma, ocasionada por trabajos de mantenimiento necesarios o acciones de terceros, o el uso de cualquier equipo o software ajeno a la plataforma; así como otros eventos afines o correlativos a los aquí indicados.</p>

            <p>6.8 HBL se reserva el derecho de cambiar palabras del contenido por errores tipográficos, si es que los detecta, ya que su corrección es obligación del usuario. Asimismo, se podrán eliminar datos o imágenes que sean notoriamente irrelevantes para el contenido de la publicación o eliminar contenido inaceptable conforme a estos términos, las leyes o los reglamentos; asó como la ética, moral o buenas costumbres.</p>

            <p>7. PROTECCIÓN DE DATOS DE TERCEROS.</p>

            <p>7.1 Los anuncios publicados en la plataforma deben ser hechos en absoluta protección de datos de terceros. En ese sentido, los usuarios ni divulgarán ninguna información de ninguna naturaleza atinente a un tercero, relacionado o no, distinto al usuario, como son datos de contacto, datos financieros, imágenes no autorizadas, entre otros. Cada usuario será el único responsable de la información que divulgue, aún sea por su propia decisión, negligencia o imprudencia.</p>

            <p>8. INCUMPLIMIENTOS.</p>

            <p>8.1 La plataforma podrá cancelar o suspender la cuenta de un usuario por violación a una o cualquiera de las reglas de estos términos y condiciones, así como por violación a leyes, reglamentos, buenos usos y costumbres, reglas de la moral o de la ética.</p>

            <p>8.2 En el caso de la suspensión o inhabilitación de un usuario, todos los anuncios que tuviera publicados serán quitados de la plataforma sin derecho de compensación o devolución de pagos por servicios a la plataforma, de haberse generado alguno.</p>
            <p>9. DAÑOS A HBL E INDEMNIZACIÓN:</p>

            <p>9.1 Los usuarios reconocen que HBL tiene su base y razón de ser en su reputación, buena imagen así como por el cabal cumplimiento de sus términos y condiciones, así como el respeto de la ley y los reglamentos, la moral y las buenas costumbres..</p>

            <p>9.2 Los usuarios se comprometen a no realizar conductas o actos que tiendan a mermar la imagen de HBL, así como su buena reputación, lo que será considerado como una falta grave que activa su obligación de indemnizar el daño integral que le sea causado.</p>
          </div>
        </div>
        <br>
      </div>
    </main>
    <MyFooter />
  </div>
</template>

<script>
export default {
  name: 'Terms',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Terms & Conditions',
    // all titles will be injected into this template
    // titleTemplate: '%s | Exclusive experience specialists',
    meta: [
      { name: 'description', content: 'Learn more about our service.' },
      // Schema.org markup for Google+
      { itemprop: 'name', content: 'Terms & Conditions | HomeBeLike' },
      { itemprop: 'description', content: 'Learn more about our service.' },
      { itemprop: 'image', content: 'https://homebelike.com/img/img-homebelike.jpg' },
      // Twitter Card data
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: 'Terms & Conditions | HomeBeLike' },
      { name: 'twitter:description', content: 'Learn more about our service.' },
      // Twitter summary card with large image must be at least 280x150px
      { name: 'twitter:image:src', content: 'https://homebelike.com/img/img-homebelike.jpg' },
      // Open Graph data
      { property: 'og:title', content: 'Terms & Conditions | HomeBeLike' },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: 'https://homebelike.com/terms' },
      { property: 'og:image', content: 'https://homebelike.com/img/img-homebelike.jpg' },
      { property: 'og:description', content: 'Learn more about our service.' },
    ]
  },
};
</script>

<style scoped>
</style>
